export const createQueryParamsForCaching = (args: {
  queryType: string;
  queryVariables?: { [key: string]: any };
}) => {
  return `queryType=${args.queryType}${
    args.queryVariables
      ? encodeURIComponent(`&variables=${JSON.stringify(args.queryVariables)}`)
      : ""
  }`;
};
