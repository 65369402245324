/* eslint-disable @next/next/no-img-element */
import cx from "classnames";
import { endpoints } from "config";
import { CartContext } from "context/cart";
import { useTranslationContext } from "context/translation";
import Link from "next/link";
import { FC, useContext } from "react";
import CartSvg from "../../svg/CartSvg";
import ChevronDownSvg from "../../svg/ChevronDownSvg";
import ProfileSvg from "../../svg/ProfileSvg";
import SodastreamLogo from "../../svg/SodastreamLogo";
import { HeaderProps } from "../Header";
import st from "./BottomHeader.module.scss";
import { HeaderDropdownItem } from "./HeaderDropdownItem";

const BottomHeader: FC<HeaderProps> = ({ headerData }) => {
  const { quickLinks } = useTranslationContext();
  const { getSize } = useContext(CartContext);
  const cartSize = getSize() || 0;

  return (
    <div className={cx(st.container, "container")}>
      <div className={st.content}>
        {/** HEADER LEFT */}
        <div className={st.leftContainer}>
          <Link href="/" shallow>
            <SodastreamLogo color="#75a7ad" width="160px" />
          </Link>
        </div>
        {/** HEADER MIDDLE */}
        <div className={st.middleContainer}>
          {/** MIDDLE LEFT PART */}
          <div className={st.middleLeftContainer}>
            {headerData?.leftDropdownItems.map((item) => {
              return <HeaderDropdownItem key={item.id} itemData={item} />;
            })}
            <div className={st.middleLeftItem}>
              {headerData?.middleLinks.map((link) => (
                <Link
                  bi-type="Menu"
                  bi-id={link.title}
                  className={st.middleLeftItemText}
                  href={link.url ?? ""}
                  key={link.id}
                  shallow
                >
                  {link.title}
                </Link>
              ))}
            </div>
          </div>
          {/** MIDDLE RIGHT DESKTOP PART */}
          <div className={st.middleRightDesktop}>
            {headerData?.rightLinks.map((link) => (
              <div key={link.id} className={st.middleRightItem}>
                <Link
                  bi-type="Menu"
                  bi-id={link.title}
                  href={link.url ?? ""}
                  className={st.middleRightItemText}
                  shallow
                >
                  {link.title}
                </Link>
              </div>
            ))}
          </div>
          {/** MIDDLE RIGHT TABLET PART */}
          <div className={st.middleRightTablet}>
            <div className={st.middleRightItem}>
              <span className={st.middleRightItemText}>{quickLinks}</span>
              <ChevronDownSvg />
              <div className={st.quickLinksMenu}>
                <div className={st.quickLinkContainer}>
                  {headerData?.rightLinks.map((link) => (
                    <div key={link.id} className={st.quickLinkText}>
                      <Link
                        bi-type="Menu"
                        bi-id={link.title}
                        href={link.url ?? ""}
                        className={st.middleRightItemText}
                        shallow
                      >
                        {link.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** HEADER RIGHT */}
        <div className={st.rightContainer}>
          {/** CART LOGO */}
          <Link
            bi-type="Menu"
            bi-id="Cart"
            className={st.cartLogo}
            href="/cart"
            shallow
          >
            {cartSize > 0 && <div className={st.cartCount}>{cartSize}</div>}
            <CartSvg color={"#ffffff"} />
          </Link>

          {/** PROFILE LOGO */}
          <Link
            bi-type="Menu"
            bi-id="My Account"
            href={endpoints.portalFrontend ?? ""}
            className={st.profileLogo}
            shallow
          >
            <ProfileSvg />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BottomHeader;
