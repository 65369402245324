import { Button } from "components/Button";
import { Alert } from "components/common/Alert/Alert";
import { useTranslationContext } from "context/translation";
import { useNewsletterForm } from "hooks/newsletter/useNewsletterForm";
import { FC } from "react";
import { StructuredText } from "react-datocms/structured-text";
import { Field, Form } from "react-final-form";
import { NewsletterFragment } from "services/datocms/generated";
import st from "./Footer.module.scss";

type FooterNewsletterProps = {
  newsletter: NonNullable<NewsletterFragment>;
};

export const FooterNewsletter: FC<FooterNewsletterProps> = ({ newsletter }) => {
  const { onSubmit, data, error } = useNewsletterForm(newsletter);
  const { unknownError, thankYouNewsletter } = useTranslationContext();

  const firstTermsComponent = (
    <div className={st.checkboxContainer}>
      {newsletter.showFirstTermsCheckbox ? (
        <>
          <Field
            component="input"
            type="checkbox"
            name="accept_marketing"
            id="acceptMarketingFooter"
            required
          />
          <label htmlFor="acceptMarketingFooter">
            <StructuredText data={newsletter.firstTerms} />
          </label>
        </>
      ) : (
        <span className={st.inputHintText}>
          <StructuredText data={newsletter.firstTerms} />
        </span>
      )}
    </div>
  );

  const secondTermsComponent = (
    <div className={st.checkboxContainer}>
      {newsletter.showSecondTermsCheckbox ? (
        <>
          <Field
            component="input"
            type="checkbox"
            name="opt_in"
            id="optInFooter"
            required
          />
          <label htmlFor="optInFooter">
            <StructuredText data={newsletter.secondTerms} />
          </label>
        </>
      ) : (
        <span className={st.belowInfoText}>
          <StructuredText data={newsletter.secondTerms} />
        </span>
      )}
    </div>
  );

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className="w-full" onSubmit={handleSubmit}>
          <div className={st.newsletterContainer}>
            {data && (
              <div className={st.newsletterThankYou}>
                <p className={st.newsletterHeading}>{thankYouNewsletter}</p>
              </div>
            )}
            <div className={st.newsletterHeading}>
              <StructuredText data={newsletter.newsletterHeader} />
            </div>
            {newsletter.showFirstTermsAbove && firstTermsComponent}
            {newsletter.showSecondTermsAbove && secondTermsComponent}
            <Field
              name="email"
              component="input"
              type="email"
              placeholder={newsletter.emailPlaceholder ?? ""}
              className={st.emailInput}
              required
            />
            {!newsletter.showFirstTermsAbove && firstTermsComponent}
            {!newsletter.showSecondTermsAbove && secondTermsComponent}
            <Button type="submit" fullWidth>
              {newsletter.subscribeButtonText}
            </Button>
            {error && <Alert type="error">{unknownError}</Alert>}
          </div>
        </form>
      )}
    </Form>
  );
};
